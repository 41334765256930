<app-ui-preloader [display]="loading"></app-ui-preloader>

<div class="row">
    <div class="col-12">
        <div class="page-title-box">
            <div class="page-title-right">
                <form class="d-flex">

                </form>
            </div>
            <h4 class="page-title" i18n>Dashboard</h4>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card widget-inline">
            <div class="card-body p-0">
                <div class="row g-0">
                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-multiple-outline text-muted" style="font-size: 24px;"></i>
                                <h3>
                                    <span *ngIf="quotationCount">{{quotationCount}}</span>
                                    <span *ngIf="!quotationCount">0</span>
                                </h3>
                                <p class="text-muted font-15 mb-0">Quotations</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-sign text-muted" style="font-size: 24px;"></i>
                                <h3>
                                    <span class="text-secondary" *ngIf="invoiceCount">{{invoiceCount}}</span>
                                    <span *ngIf="!invoiceCount">0</span>
                                </h3>
                                <p class="text-muted font-15 mb-0">Invoice</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-sign text-muted" style="font-size: 24px;"></i>
                                <h3>
                                    <span class="text-primary" *ngIf="receiptCount">{{receiptCount}}</span>
                                    <span *ngIf="!receiptCount">0</span>
                                </h3>
                                <p class="text-muted font-15 mb-0">Receipt</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-sign text-muted" style="font-size: 24px;"></i>
                                <h3>
                                    <span class="text-success" *ngIf="bookingCount">{{bookingCount}}</span>
                                    <span *ngIf="!bookingCount">0</span>
                                </h3>
                                <p class="text-muted font-15 mb-0">Bookings</p>
                            </div>
                        </div>
                    </div>    

                </div> <!-- end row -->
            </div>
        </div> <!-- end card-box-->
    </div> <!-- end col-->
</div>

<div class="row">
    <div class="col-lg-6">
        <div class="card text-white bg-info overflow-hidden" role="button" (click)="navigateTo('invoice')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-document-edit-outline"></i> Invoice</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card text-white bg-danger overflow-hidden" role="button" (click)="navigateTo('quotation')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-chart-outline"></i> Quotations</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card text-white bg-primary overflow-hidden" role="button" (click)="navigateTo('receipt')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-chart-outline"></i> Receipt</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card text-white bg-success overflow-hidden" role="button" (click)="navigateTo('booking')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-chart-outline"></i> Booking</h4>
                </div>
            </div>
        </div>
    </div>

</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyA0s45vi918-8bimEsBzL_vGUzlUyc4p9s",
    authDomain: "thermal-creek-410815.firebaseapp.com",
    projectId: "thermal-creek-410815",
    storageBucket: "thermal-creek-410815.appspot.com",
    messagingSenderId: "752918533546",
    appId: "1:752918533546:web:a73044297b34c54aca87d3"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

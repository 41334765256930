import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgbAlertModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { VistaServicesService } from 'src/app/core/service/vista-services.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';

interface MoneyHistory {
  title: string;
  amount: number;
  variant: string;
  icon: string;
}

interface CardDropdownOption {
  label: string;
  icon?: string;
  variant?: string;
  hasDivider?: boolean;
}

const MONEYHISTORY: MoneyHistory[] = [
  {
    title: 'Income',
    icon: 'mdi mdi-arrow-up-bold-outline',
    amount: 276548,
    variant: 'primary'
  },
  {
    title: 'Expenses',
    icon: 'mdi mdi-arrow-down-bold-outline',
    amount: 50216,
    variant: 'danger'
  },
  {
    title: 'Transfar',
    icon: 'mdi mdi-swap-horizontal',
    amount: 98100,
    variant: 'success'
  }
];

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  statWidget: { title: string; value: number; change: string; arrow: string; subtitle: string; icon: string; }[];
  loading: boolean = false;
  dropdownOptions: CardDropdownOption[] = [];
  moneyHistory: MoneyHistory[] = [];
  subscription: Subscription = new Subscription();

  quotationCount: number = 0;
  invoiceCount: number = 0;
  receiptCount: number = 0;
  bookingCount: number = 0;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private vistaService: VistaServicesService,
  ) {

    this.subscription.add(
      this.vistaService.getQuotationArray().pipe().subscribe((res: any[]) => {
        if (res) {
          let xy = res;
          this.quotationCount = 0;
          xy.forEach(element => {
            this.quotationCount += 1;
          });
        }
      }));

    this.subscription.add(
      this.vistaService.getInvoiceArray().pipe().subscribe((res: any[]) => {
        if (res) {
          let xy = res;
          this.invoiceCount = 0;

          xy.forEach(element => {
            this.invoiceCount += 1;
          });
        }
      }));

    this.subscription.add(
      this.vistaService.getReceiptArray().pipe().subscribe((res: any[]) => {
        if (res) {
          let xy = res;
          this.receiptCount = 0;

          xy.forEach(element => {
            this.receiptCount += 1;
          });
        }
      }));

    this.subscription.add(
      this.vistaService.getBookingArray().pipe().subscribe((res: any[]) => {
        if (res) {
          let xy = res;
          this.bookingCount = 0;

          xy.forEach(element => {
            this.bookingCount += 1;
          });
        }
      }));
  }

  ngOnInit(): void {
    this.moneyHistory = MONEYHISTORY;
    this.dropdownOptions = [
      { label: 'Refresh', icon: 'mdi mdi-cached' },
      { label: 'Edit', icon: 'mdi mdi-circle-edit-outline' },
      { label: 'Remove', icon: 'mdi mdi-delete-outline', variant: 'danger' },
    ];

    this.statWidget = [
      {
        "title": "Active Users",
        "value": 121,
        "change": "5.27%",
        "arrow": 'up',
        "subtitle": 'Since last month',
        "icon": 'uil uil-users-alt',
      },
      {
        title: 'Views per minute',
        value: 560,
        change: '1.08%',
        arrow: 'down',
        subtitle: 'Since previous week',
        icon: 'uil uil-window-restore'
      },
      {
        title: 'Views per minute',
        value: 560,
        change: '1.08%',
        arrow: 'down',
        subtitle: 'Since previous week',
        icon: 'uil uil-window-restore'
      }
    ]
  }

  navigateTo(type: string) {

    if (type === "quotation") {
      this.router.navigate([`/portal/quotation`]);
      this.toastr.info(`View/Edit Quotation List!`, "Info!");
    };

    if (type === "invoice") {
      this.router.navigate([`/portal/invoice`]);
      this.toastr.info(`View/Edit Invoice List`, "Info!");
    };

    if (type === "receipt") {
      this.router.navigate([`/portal/receipt`]);
      this.toastr.info(`View/Edit Receipt List`, "Info!");
    };

    if (type === "booking") {
      this.router.navigate([`/portal/booking`]);
      this.toastr.info(`View/Edit Booking List`, "Info!");
    };

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}

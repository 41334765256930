<div class="card bg-transparent">
    <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->
    <!-- Header -->
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Receipt</h1>
                    <p class="lead text-muted">Fill in the receipt detail.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">1. General Details :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="generalDetailsForm">

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="date" class="form-label">Receipt Date<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="date" id="date" placeholder="Enter the receipt date..."
                                required formControlName="date"
                                [ngClass]="{'is-invalid': (formSubmitted && generalDetailsFormValues['date'].invalid)||(generalDetailsFormValues['date'].touched && generalDetailsFormValues['date'].invalid)}">
                            <div *ngIf="(formSubmitted && generalDetailsFormValues['date'].invalid)||(generalDetailsFormValues['date'].touched && generalDetailsFormValues['date'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="generalDetailsFormValues['date'].errors?.['required']">
                                    Receipt Date is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="id" class="form-label">Receipt Id.<span class="text-danger"> *</span></label>
                            <input class="form-control" type="text" id="id" required readonly formControlName="id"
                                [ngClass]="{'is-invalid': (formSubmitted && generalDetailsFormValues['id'].invalid)||(generalDetailsFormValues['id'].touched && generalDetailsFormValues['id'].invalid)}">
                            <div *ngIf="(formSubmitted && generalDetailsFormValues['id'].invalid)||(generalDetailsFormValues['id'].touched && generalDetailsFormValues['id'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="generalDetailsFormValues['id'].errors?.['required']">
                                    Receipt Id is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>

        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">2. Client :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="receiptClientForm">

                    <div class="row">
                        
                        <div class="col-md-4 mb-3">
                            <label for="firstName" class="form-label">First Name<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="text" id="firstName" placeholder="Enter a first name"
                                required formControlName="firstName"  minLength="3" maxLength="15"
                                [ngClass]="{'is-invalid': (formSubmitted && receiptClientFormValues['firstName'].invalid)||(receiptClientFormValues['firstName'].touched && receiptClientFormValues['firstName'].invalid)}">
                            <div *ngIf="(formSubmitted && receiptClientFormValues['firstName'].invalid)||(receiptClientFormValues['firstName'].touched && receiptClientFormValues['firstName'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="receiptClientFormValues['firstName'].errors?.['required']">
                                    First Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="surname" class="form-label">Surname<span class="text-danger"> *</span></label>
                            <input class="form-control" type="text" id="surname" placeholder="Enter a surname" required
                                formControlName="surname"  minLength="3" maxLength="15"
                                [ngClass]="{'is-invalid': (formSubmitted && receiptClientFormValues['surname'].invalid)||(receiptClientFormValues['surname'].touched && receiptClientFormValues['surname'].invalid)}">
                            <div *ngIf="(formSubmitted && receiptClientFormValues['surname'].invalid)||(receiptClientFormValues['surname'].touched && receiptClientFormValues['surname'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="receiptClientFormValues['surname'].errors?.['required']">
                                    Surname is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="otherName" class="form-label">Other Name</label>
                            <input class="form-control" type="text" id="otherName" placeholder="Enter another name"
                                required formControlName="otherName" minLength="3" maxLength="15"
                                [ngClass]="{'is-invalid': (formSubmitted && receiptClientFormValues['otherName'].invalid)||(receiptClientFormValues['otherName'].touched && receiptClientFormValues['otherName'].invalid)}">
                            <div *ngIf="(formSubmitted && receiptClientFormValues['otherName'].invalid)||(receiptClientFormValues['otherName'].touched && receiptClientFormValues['otherName'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="receiptClientFormValues['otherName'].errors?.['required']">
                                    Other Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label for="address" class="form-label">Address<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="text" id="address"  minLength="3" maxLength="30"
                                placeholder="Enter the company address." required formControlName="address"
                                [ngClass]="{'is-invalid': (formSubmitted && receiptClientFormValues['address'].invalid)||(receiptClientFormValues['address'].touched && receiptClientFormValues['address'].invalid)}">
                            <div *ngIf="(formSubmitted && receiptClientFormValues['address'].invalid)||(receiptClientFormValues['address'].touched && receiptClientFormValues['address'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="receiptClientFormValues['address'].errors?.['required']">
                                    Company Address is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Phone Number</label>
                            <div class="input-group flex-nowrap">
                                <span class="input-group-text" id="basic-addon1">+254</span>
                                <input type="text" class="form-control" placeholder="722 000 000"
                                    formControlName="phoneNumber" aria-label="Username" aria-describedby="basic-addon1"
                                    [ngClass]="{'is-invalid': invalidPhoneNumber}">
                                <div *ngIf="invalidPhoneNumber" class="invalid-tooltip">
                                    <span>
                                        Write a valid Phone Number.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="email" class="form-label">Email address <span
                                    class="text-danger">*</span></label>
                            <input class="form-control" type="email" id="email" placeholder="Enter an email"  minLength="3" maxLength="30"
                                formControlName="email">
                        </div>
                    </div>
                </form>

            </div>

            <div class="card-body">
                <div class="card border border-primary">

                    <div class="card-body">
                        <!-- Receipt To: -->
                        <span>
                            <h5 class="my-0">Receipt To:</h5><br />
                        </span>

                        <!-- Travel : -->

                        <table class="table table-sm table-centered table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="p-0 text-muted">Company Address: </th>
                                    <th class="p-0">
                                        <span
                                            *ngIf="receiptClientForm.value['address']">{{receiptClientForm.value['address']}}<br /></span>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="p-0 text-muted">Addressed to: </th>
                                    <th class="p-0">
                                        <span *ngIf="receiptClientForm.value['firstName']">
                                            {{receiptClientForm.value['firstName']}}
                                        </span>
                                        <span *ngIf="receiptClientForm.value['otherName']">
                                            {{receiptClientForm.value['otherName']}}
                                        </span>
                                        <span *ngIf="receiptClientForm.value['surname']">
                                            {{receiptClientForm.value['surname']}}
                                        </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="p-0 text-muted">Email: </th>
                                    <th class="p-0">
                                        <span
                                            *ngIf="receiptClientForm.value['email']">{{receiptClientForm.value['email']}}<br /></span>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="p-0 text-muted">Phone Number: </th>
                                    <th class="p-0">
                                        <span *ngIf="receiptClientForm.value['phoneNumber']">+254
                                            {{receiptClientForm.value['phoneNumber']}}<br /></span>
                                    </th>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>

        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">3. Receipt Items :</h4>
            </div>
            <div class="card-body">
                <!-- form -->

                <form name="signup-form" [formGroup]="vistaForm">
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label for="description" class="form-label">Item Details<span class="text-danger">
                                    *</span></label>
                            <textarea class="form-control form-control-sm " type="text" id="description" minLength="3" maxLength="30"
                                formControlName="description" rows="2" placeholder="Write the item description."
                                [ngClass]="{'is-invalid': (formSubmitted && vistaFormValues['description'].invalid)||(vistaFormValues['description'].touched && vistaFormValues['description'].invalid)}"></textarea>
                            <div *ngIf="(formSubmitted && vistaFormValues['description'].invalid)||(vistaFormValues['description'].touched && vistaFormValues['description'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="vistaFormValues['description'].errors?.['required']">
                                    Description is required.
                                </div>
                            </div>
                        </div>

                        <div class="col-md-5 mb-3">
                            <label for="rate" class="form-label">Rate<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="number" id="rate" placeholder="Enter your first name"
                                required formControlName="rate" min="1" max="100000"
                                [ngClass]="{'is-invalid': (formSubmitted && vistaFormValues['rate'].invalid)||(vistaFormValues['rate'].touched && vistaFormValues['rate'].invalid)}">
                            <div *ngIf="(formSubmitted && vistaFormValues['rate'].invalid)||(vistaFormValues['rate'].touched && vistaFormValues['rate'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="vistaFormValues['rate'].errors?.['required']">
                                    Rate is required.
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 mb-3">
                            <label for="quantity" class="form-label">Quantity<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="number" id="quantity" placeholder="Enter your first name"
                                required formControlName="quantity" min="1" max="100"
                                [ngClass]="{'is-invalid': (formSubmitted && vistaFormValues['quantity'].invalid)||(vistaFormValues['quantity'].touched && vistaFormValues['quantity'].invalid)}">
                            <div *ngIf="(formSubmitted && vistaFormValues['quantity'].invalid)||(vistaFormValues['quantity'].touched && vistaFormValues['quantity'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="vistaFormValues['quantity'].errors?.['required']">
                                    Quantity is required.
                                </div>
                            </div>
                        </div>

                        <div class="col-md-5 mb-3">
                            <label for="itemTotal" class="form-label">Item Total<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="number" id="itemTotal" placeholder="Enter your first name"
                                required formControlName="itemTotal" readonly
                                [ngClass]="{'is-invalid': (formSubmitted && vistaFormValues['itemTotal'].invalid)||(vistaFormValues['itemTotal'].touched && vistaFormValues['itemTotal'].invalid)}">
                            <div *ngIf="(formSubmitted && vistaFormValues['itemTotal'].invalid)||(vistaFormValues['itemTotal'].touched && vistaFormValues['itemTotal'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="vistaFormValues['itemTotal'].errors?.['required']">
                                    Item Total is required.
                                </div>
                            </div>
                        </div>

                    </div>
                </form>

                <div class="row">
                    <div class="col-12">
                        <div class="d-grid">
                            <button class="btn btn-success" (click)="addReceiptItem(vistaForm.value)"
                                type="submit" *ngIf="!updateInProgress"><i class=""></i>Add Item
                            </button>
                            <button class="btn btn-primary" type="button" disabled
                                *ngIf="updateInProgress">
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Vista Fee : -->
                        <span>
                            <h5 class="my-0">Receipt Items :</h5><br />
                        </span>
                        <div class="table-responsive-sm" *ngIf="vistaForm.value">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1 w-10">
                                            No.
                                        </th>
                                        <th class="p-0 px-1 w-30">
                                            Item Details
                                        </th>
                                        <th class="p-0 px-1 w-10">
                                            Rate
                                        </th>
                                        <th class="p-0 px-1 w-20">
                                            Quantity
                                        </th>
                                        <th class="p-0 px-1 w-20">
                                            Item Total
                                        </th>
                                        <th class="p-0 px-1 w-10"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let m of receiptItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.description">
                                                {{m.description}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.rate">
                                                {{m.rate | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.quantity">
                                                {{m.quantity}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.itemTotal">
                                                {{m.itemTotal | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <th class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeReceiptItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </th>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="totalForm.value">
                                    <tr><br /></tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0 px-1">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1 text-center">

                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="totalForm.value['totalCost']">
                                                {{totalForm.value['totalCost'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">4. Notes :</h4>
            </div>
            <div class="card-body">
                <!-- form -->



                <div class="row">

                    <form name="signup-form" [formGroup]="noteForm" *ngIf="noteForm">
                        <div>
                            <div class="input-group input-group-sm">
                                <span class="input-group-text" id="inputGroup-sizing-sm">{{notesItemArray.length +
                                    1}}</span>
                                <input class="form-control" type="text" id="description"
                                    placeholder="The airline ticket prices may change depending on time & availability."
                                    required formControlName="description"  minLength="3" maxLength="60"
                                    [ngClass]="{'is-invalid': (formSubmitted && noteFormValues['description'].invalid)||(noteFormValues['description'].touched && noteFormValues['description'].invalid)}">
                                <button class="btn btn-outline-success" *ngIf="!submitInProgress && !updateInProgress"
                                    (click)="addNoteItem(noteForm.value)" type="button">Add Item</button>
                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </form>


                    <!-- <form name="signup-form" [formGroup]="noteForm"
                            *ngIf="showProjectDealerOperatingExpenseEdit">
                            <div>
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text"
                                        id="inputGroup-sizing-sm">{{notesItemArray.length + 1}}</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" placeholder="Cost" id="total"
                                        aria-label="Sizing example input" formControlName="total"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-success"
                                        *ngIf="!submitInProgress && !updateInProgress"
                                        (click)="addDealerOperatingExpenseItem(noteForm.value,'salariesAndBonus')"
                                        type="button">Add Item</button>
                                    <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                        *ngIf="submitInProgress || updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </form> -->
                </div>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Notes To: -->
                        <span>
                            <h5 class="my-0">Notes :</h5><br />
                        </span>
                        <div class="row text-center" *ngIf="!(notesItemArray.length > 0)">
                            <div class="row flex-grow-1">
                                <div class="col">
                                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                        <h1 class="display-4">Empty</h1>
                                        <p class="lead">No Notes!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive-sm" *ngIf="(notesItemArray.length > 0)">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1">
                                            No.
                                        </th>
                                        <th class="p-0 px-1">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of notesItemArray; let i = index;">
                                        <td class="p-0 px-1">{{i+1}}.</td>
                                        <td class="p-0 px-1">{{m}}</td>
                                        <td class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeNoteItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">5. Preview :</h4>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-md-12">
                        <div class="card border border-primary">
                            <div class="pricing-header px-0 py-0 text-center">
                                <h1 class="display-6">Receipt</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="card border border-primary"
                            *ngIf="(receiptClientForm.value['firstName']) || (receiptClientForm.value['otherName']) || (receiptClientForm.value['surname'])">
                            <div class="card-body">
                                <!-- Receipt To: -->
                                <span>
                                    <h5 class="my-0">Receipt To:</h5><br />
                                </span>

                                <!-- Travel : -->

                                <table class="table table-sm table-centered table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="p-0 text-muted">Company Address: </th>
                                            <th class="p-0">
                                                <span
                                                    *ngIf="receiptClientForm.value['address']">{{receiptClientForm.value['address']}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Addressed to: </th>
                                            <th class="p-0">
                                                <span *ngIf="receiptClientForm.value['firstName']">
                                                    {{receiptClientForm.value['firstName']}}
                                                </span>
                                                <span *ngIf="receiptClientForm.value['otherName']">
                                                    {{receiptClientForm.value['otherName']}}
                                                </span>
                                                <span *ngIf="receiptClientForm.value['surname']">
                                                    {{receiptClientForm.value['surname']}}
                                                </span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Email: </th>
                                            <th class="p-0">
                                                <span
                                                    *ngIf="receiptClientForm.value['email']">{{receiptClientForm.value['email']}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Phone Number: </th>
                                            <th class="p-0">
                                                <span *ngIf="receiptClientForm.value['phoneNumber']">+254
                                                    {{receiptClientForm.value['phoneNumber']}}<br /></span>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card border border-primary" *ngIf="companyName || loggedInUserDisplayName">
                            <div class="card-body">
                                <!-- Receipt To: -->
                                <span>
                                    <h5 class="my-0">Receipt From:</h5><br />
                                </span>

                                <table class="table table-sm table-centered table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="p-0 text-muted">Addressed to: </th>
                                            <th class="p-0">
                                                <span
                                                    *ngIf="loggedInUserDisplayName">{{loggedInUserDisplayName}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Email: </th>
                                            <th class="p-0">
                                                <span *ngIf="companyEmail">{{companyEmail}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Phone Number: </th>
                                            <th class="p-0">
                                                <span *ngIf="companyPhoneNumber">+254
                                                    {{companyPhoneNumber}}<br /></span>
                                            </th>
                                        </tr>
                                        <br />
                                        <tr>
                                            <th class="p-0 text-muted">Receipt No: </th>
                                            <th class="p-0">
                                                <span *ngIf="receiptId">
                                                    {{receiptId}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Date: </th>
                                            <th class="p-0">
                                                <span *ngIf="receiptDateCreated">
                                                    {{receiptDateCreated}}<br /></span>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>



                <div class="card border border-primary" *ngIf="(receiptItemArray.length>0)">
                    <div class="card-body">

                        <span>
                            <h5 class="my-0">Receipt Items :</h5><br />
                        </span>

                        <div class="table-responsive-sm" *ngIf="vistaForm.value">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1 w-10">
                                            No.
                                        </th>
                                        <th class="p-0 px-1 w-30">
                                            Item Details
                                        </th>
                                        <th class="p-0 px-1 w-10">
                                            Rate
                                        </th>
                                        <th class="p-0 px-1 w-20">
                                            Quantity
                                        </th>
                                        <th class="p-0 px-1 w-20">
                                            Item Total
                                        </th>
                                        <th class="p-0 px-1 w-10"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let m of receiptItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.description">
                                                {{m.description}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.rate">
                                                {{m.rate | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.quantity">
                                                {{m.quantity}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.itemTotal">
                                                {{m.itemTotal | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <th class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeReceiptItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </th>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="totalForm.value">
                                    <tr><br /></tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0 px-1">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1 text-center">

                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="totalForm.value['totalCost']">
                                                {{totalForm.value['totalCost'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card border border-primary" *ngIf="(notesItemArray.length>0)">
                    <div class="card-body">
                        <!-- Notes -->
                        <span>
                            <h5 class="my-0">Notes :</h5><br />
                        </span>

                        <div class="row text-center" *ngIf="!(notesItemArray.length > 0)">
                            <div class="row flex-grow-1">
                                <div class="col">
                                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                        <h1 class="display-4">Empty</h1>
                                        <p class="lead">No Notes!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive-sm" *ngIf="(notesItemArray.length>0)">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1">
                                            No.
                                        </th>
                                        <th class="p-0 px-1">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of notesItemArray; let i = index;">
                                        <td class="p-0 px-1">{{i+1}}.</td>
                                        <td class="p-0 px-1">{{m}}</td>
                                        <td class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeNoteItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>







            </div>
        </div>

    </div>

    <div class="card-footer">
        <div class="card mb-0 bg-success text-white" role="button" (click)="navigateTo('receiptPDF')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-download-outline"></i> Print Receipt (PDF)</h4>
                </div>
            </div>
        </div>
    </div>


</div>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// auth guard
import { AuthGuard } from './core/guards/auth.guard';

// components
import { PrivateLayoutComponent } from './layouts/private-layout/private-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'portal',
    pathMatch: 'full'
  },
  {
    path: "",
    component: PrivateLayoutComponent,
    canActivate: [           
      AuthGuard,
    ],
    children: [
      { path: 'portal', loadChildren: () => import('./portal/portal-route').then(m => m.PORTAL_ROUTES) },
      { path: 'admin', loadChildren: () => import('./admin/admin-route').then(m => m.ADMIN_ROUTES) },
      
    ]
  },
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      { path: 'account', loadChildren: () => import('./accounts/accounts-route').then(m => m.ACCOUNTS_ROUTES) },
      { path: 'error', loadChildren: () => import('./common/error/error-route').then(m => m.ERROR_ROUTES) },
      { path: 'maintenance', loadChildren: () => import('./common/maintenance/maintenance-route').then(m => m.MAINTENANCE_ROUTES) },
      { path: 'help', loadChildren: () => import('./help/help-route').then(m => m.HELP_ROUTES) },
      { path: 'support', loadChildren: () => import('./support/support-route').then(m => m.SUPPORT_ROUTES) },
      { path: 'home', loadComponent: () => import('./common/landing/landing.component').then(m => m.LandingComponent) },
      { path: 'privacy', loadComponent: () => import('./common/privacy/privacy.component').then(m => m.PrivacyComponent) },
      { path: 'contact-us', loadComponent: () => import('./common/contact-us/contact-us.component').then(m => m.ContactUsComponent) },
    ]
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {
    anchorScrolling: 'enabled',
    //  enableTracing: true //<-- debugging purposes only
    //  ,
    //relativeLinkResolution: 'legacy'
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }

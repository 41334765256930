import { CommonModule } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@firebase/auth-types';
import { NgbAlert, NgbAlertModule, NgbButtonsModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, first } from 'rxjs';
import { BookingItem } from 'src/app/core/models-two/BookingItem';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { VistaServicesService } from 'src/app/core/service/vista-services.service';
import { AdvancedTableComponent, Column } from 'src/app/shared/advanced-table/advanced-table.component';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { WidgetModule } from 'src/app/shared/widget/widget.module';

@Component({
  selector: 'app-booking-list',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
    NgbTooltipModule,
  ],
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.scss']
})
export class BookingListComponent implements OnInit, OnDestroy {


  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //projectArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: User;
  bookingObject: BookingItem;
  bookingArray;
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultBookingArray: any[] = [];
  defaultBookingObject: any;
  importedBookingArray: BookingItem[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalBookingArray: BookingItem[] = [];
  workingBookingArray: BookingItem[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private vistaService: VistaServicesService,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );
  }

  ngOnInit(): void {

    // get user data
    this.getBookingList();
    // initialize advance table 
    this.initAdvancedTableData();

  }

  ngAfterViewInit(): void {

  }


  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getBookingList() {
    //this.petroleumProductArray = users;
    this.subscription.add(
      this.vistaService.getBookingArray().pipe().subscribe((bookingList: any) => {
        if (bookingList) {
          this.workingBookingArray = [];
          this.defaultBookingArray = this.bookingArray = bookingList;

          // Arranged Alphabetically
          bookingList.forEach(element => {
            this.workingBookingArray.push(element);
          });

          this.alphabeticalBookingArray = this.workingBookingArray.sort((a: any, b: any) => (a.clientFirstName).localeCompare(b.clientFirstName));

        }
      })
    );

    this.subscription.add(
      this.vistaService.getBookingObject().pipe().subscribe((bookingList) => {
        if (bookingList) {
          this.defaultBookingObject = this.bookingObject = bookingList;
        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'name',
        label: 'Name',
        formatter: (p: BookingItem) => {
            let firstName = "";
            let otherName = "";
            let surname = "";
            if (p.clientFirstName) {
              firstName = p.clientFirstName;
            };
            if (p.clientOtherName) {
              otherName = p.clientOtherName;
            };
            if (p.clientSurname) {
              surname = p.clientSurname;
            };
            return `${firstName} ${surname} ${otherName}`;
        },
      },
      {
        name: 'id',
        label: 'Id',
        formatter: (p: BookingItem) => p.bookingId,
      },
      {
        name: 'created',
        label: 'Date',
        formatter: (p: BookingItem) => {
          if (p.date) {
            return (new Date(p.date)).toLocaleString();
          } else {

          }
        },
      },
      {
        name: 'quick link',
        label: 'Quick Links',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.view-edit').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to report page
        // this.router.navigate([`portal/booking/${e.id}`]);

      });
    })
    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {
        if (confirm("Are you sure you want to delete this booking?")) {
          // Move item to petroleumProduct/delete
          this.deleteBooking(e.id);
        }
      });
    })
  }


  deleteBooking(id: string) {

    let bookingName = "";
    bookingName = `${this.bookingArray[id].firstName} ${this.bookingArray[id].otherName} ${this.bookingArray[id].surname}`;

    this.subscription.add(
      this.vistaService.deleteItem("booking",id)
        .pipe(first()).subscribe((result) => {

          //Get User Data
          this.show_alert = true;
          this.alert_message = `Booking for ${bookingName} Deleted!`;
          this.alert_type = "success";

          this.toastr.success(`Booking for ${bookingName} Deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
          }, 3000);

        },
          (error: string) => {
            this.toastr.error(`${bookingName} was not Deleted, error: ${error}!`, "Info!");
            this.error = error;
            this.loading = false;
          }));
  }

  // table action cell formatter
  userActionFormatter(a: BookingItem): SafeHtml {
      if (a.bookingId) {
        return this.sanitizer.bypassSecurityTrustHtml(
          `<button id="${a.bookingId}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 view-edit" type="button" *ngIf="!updateInProgress"> <i class=""></i>Download PDF</button>
            <button id="${a.bookingId}" class="btn btn-sm btn-outline-danger mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 delete" type="button" *ngIf="!updateInProgress"> <i class=""></i>X</button>`
        )
      }

    // <button id="${a.bookingId}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 postInvestment" type="button" *ngIf="!updateInProgress"> <i class=""></i>Post Investment</button>
  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: BookingItem;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.bookingArray = this.defaultBookingArray;
      //this.getBookingList();
    } else {
      this.bookingArray = [...this.bookingArray].sort((a, b) => {
        const res = this.compare(a["0"][event.column], b["0"][event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: BookingItem, term: string) {
    return tableRow.bookingId?.toLowerCase().includes(term)
      || tableRow.clientFirstName?.toLowerCase().includes(term)
      || tableRow.clientOtherName?.toLowerCase().includes(term)
      || tableRow.clientSurname?.toLowerCase().includes(term)
      || tableRow.clientEmail?.toLowerCase().includes(term);
  };

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getBookingList();
      this.bookingArray = this.defaultBookingArray;
    }
    else {
      let updatedData = this.bookingArray = this.defaultBookingArray;
      //  filter
      updatedData = updatedData.filter(a => this.matches(a, searchTerm));
      this.bookingArray = updatedData;
    }
  }

  hasChanged($event: any) {
    // Clear all other address details?
  }



  createRecord() {
    this.router.navigate([`portal/booking/create`]);
  }




  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}



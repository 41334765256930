import { CommonModule, formatCurrency, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PRIMARY_OUTLET, Router, RouterLink } from '@angular/router';
import { NgbAlert, NgbAlertModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { first, Subscription } from 'rxjs';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { VistaServicesService } from 'src/app/core/service/vista-services.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Volkhov } from 'src/assets/fonts/Volkhov-normal';
import jsPDF from 'jspdf';


@Component({
  selector: 'app-receipt',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  subscription: Subscription = new Subscription();
  tree: any;
  g: any;
  s: any;
  authState: boolean = false;
  loggedInUserDate: number;
  userId: any;
  loggedInUserFirstName: any;
  loggedInUserSurname: any;
  loggedInUserLastName: any;
  loggedInUserRole: any;
  profilePictureUrl: any;
  userName: string;

  loading: boolean = false;
  dateToday: string;

  receiptClientForm!: FormGroup;
  generalDetailsForm!: FormGroup;
  vistaForm!: FormGroup;
  totalForm!: FormGroup;
  noteForm!: FormGroup;

  updateInProgress: boolean = false;
  submitInProgress: boolean = false;
  previousClientListArray: any[] = [];
  formSubmitted: boolean = false;
  invalidPhoneNumber: boolean = false;
  airlineListArray: string[] = [];
  personCountArray: number[] = [];
  notesItemArray: string[] = [];
  kenyaShillingToUSDExchangeRate: number = 128;
  dayCountArray: number[] = [];
  receiptClientName: any = "Jane Doe";
  receiptDateCreated: string;
  showForm: boolean;
  receiptId: any;
  idRequested: boolean = false;
  receiptItemArray: any[] = [];

  companyName: string = "Vista Services";
  loggedInUserDisplayName: string = "Rebecca N.";
  companyEmail: string = "info@vistaservices.co.ke";
  companyPhoneNumber: string = "+254 718 862 006";
  sessionTypeArray: string[];
  receiptCompanyAddress: any;
  receiptCompanyName: any;
  receiptClientPhoneNumber: any;
  receiptClientEmail: any;
  receiptTotalQuantity: number = 0;
  receiptTotalAmount: number = 0;
  error: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private scroller: ViewportScroller,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private vistaService: VistaServicesService,
    private authenticationService: AuthenticationService,
  ) {
    this.tree = router.parseUrl(router.url);
    this.g = this.tree.root.children[PRIMARY_OUTLET];
    this.s = this.g.segments;

    // this.loadingAnimationFourSeconds();

    this.subscription.add(authenticationService.currentUser.pipe().subscribe((res: AppUser) => {

      if (res) {
        this.authState = true;

        if (res[0]) {
          let firstName = "";
          let otherName = "";
          let surname = "";

          this.loggedInUserDate = (new Date()[Symbol.toPrimitive]('number'));
          if (res[0].userId) { this.userId = res[0].userId; };
          if (res[0].firstName) {
            this.loggedInUserFirstName = res[0].firstName;
            firstName = res[0].firstName;
          };
          if (res[0].surname) {
            this.loggedInUserSurname = res[0].surname;
            surname = res[0].surname;
          };
          if (res[0].otherName) {
            this.loggedInUserLastName = res[0].otherName;
            otherName = res[0].otherName;
          };
          if (res[0].role) { this.loggedInUserRole = res[0].role; };
          if (res[0].profilePicture) {
            if (res[0].profilePicture[0]) {
              if (res[0].profilePicture[0].url) {
                this.profilePictureUrl = res[0].profilePicture[0].url;
              } else {
                this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
              }
            } else {
              this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
            }
          } else {
            this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
          }

          this.userName = `${firstName} ${otherName} ${surname}`;
        } else {
          this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
        }

      } else {
        this.authState = false;
      }

    }));

  };

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  navigateTo(type: string) {

    if (type === "receiptPDF") {
      this.saveReceipt();
      this.printReceiptPDF();
    }

  }

  saveReceipt() {
    this.submitInProgress = true;

    let preparedBy = "";
    let clientFirstName = "";
    let clientOtherName = "";
    let clientSurname = "";
    let clientEmail = "";
    let clientPhoneNumber = 0;
    let receiptId = "";
    let receiptItems = [];
    let receiptTotalCost = 0;
    let notes = [];
    
    let date = 0;
    let load: any = {};

    preparedBy = this.userName
    clientFirstName = this.receiptClientForm.get("firstName").value;
    clientOtherName = this.receiptClientForm.get("otherName").value;
    clientSurname = this.receiptClientForm.get("surname").value;
    clientEmail = this.receiptClientForm.get("email").value;
    clientPhoneNumber = this.receiptClientForm.get("phoneNumber").value;
    receiptId = this.generalDetailsForm.get("id").value;
    receiptItems = this.receiptItemArray;
    receiptTotalCost = this.totalForm.get("totalCost").value;
    notes = this.notesItemArray;

    date = new Date()[Symbol.toPrimitive]('number');

    load = {
      preparedBy: preparedBy,
      clientFirstName: clientFirstName,
      clientOtherName: clientOtherName,
      clientSurname: clientSurname,
      clientEmail: clientEmail,
      clientPhoneNumber: clientPhoneNumber,
      receiptId: receiptId,
      receiptItems: receiptItems,
      receiptTotalCost: receiptTotalCost,
      notes: notes,
      
      date: date
    };

    // Checks
    // if (this.projectForm.controls['id'].invalid) {
    //   this.show_alert = true;
    //   this.alert_message = "Project Id is missing!";
    //   this.alert_type = "danger";

    //   this.submitInProgress = false;
    //   this.toastr.error('Project Id is missing!', 'Error!');

    //   setTimeout(() => {
    //     this.show_alert = false;
    //     this.selfClosingAlert.close();
    //   }, 3000);
    //   return;
    // };

    this.subscription.add(
      this.vistaService.saveReceipt(load)
        .pipe(first()).subscribe((result) => {
          this.formSubmitted = true;
          this.submitInProgress = false;
          this.toastr.success(`Receipt for ${load.clientFirstName} ${load.clientOtherName} ${load.clientSurname} Created`, "Info!");
          this.router.navigate([`/portal/receipt/${load.receiptId}`]);
          // this.projectForm.reset();
        },
          (error: string) => {
            this.error = error;
            this.submitInProgress = false;
          }));

  }

  ngOnInit(): void {

    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');
    this.dateToday = `${day}-${month}-${year}`;
    this.receiptDateCreated = this.dateToday;

    this.airlineListArray = ["Air India", "Kenya Airways", "Air Asia", "Indigo"];

    this.sessionTypeArray = ["Full", "Half"];

    this.notesItemArray = [
      "Approximate dollar exchange rate is $1.00 = 128.00 KES.",
      "Hotel stay in a budget hotel includes breakfast & tax.",
      "The Airline Ticket Prices may change depending on time & availability.",
    ];

    this.personCountArray = [1, 2, 3, 4, 5];

    for (let index = 0; index < 45; index++) {
      this.dayCountArray.push((index + 1));
    }

    this.previousClientListArray = [
      {
        "displayName": "3rd Park",
        "id": "001",
      },
      {
        "displayName": "Aga Khan",
        "id": "002",
      }
    ];

    this.generalDetailsForm = this.fb.group({
      id: [''],
      date: [''],
    });

    this.subscription.add(
      this.generalDetailsForm.get("id").valueChanges.subscribe(x => {
        this.receiptId = x;
      })
    );

    this.subscription.add(
      this.generalDetailsForm.get("date").valueChanges.subscribe(x => {
        let pickedDate = new Date(((x.substring(0, 4)) + "-" + (x.substring(5, 7)) + "-" + (x.substring(8, 10))))[Symbol.toPrimitive]('number');
        console.log("pickedDate: ", pickedDate);
      })
    );

    this.receiptClientForm = this.fb.group({
      address: [''],
      firstName: [''],
      otherName: [''],
      surname: [''],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
    });

    this.subscription.add(
      this.receiptClientForm.get("firstName").valueChanges.subscribe(x => {
        this.receiptClientName = `${this.receiptClientForm.get("firstName").value} ${this.receiptClientForm.get("otherName").value} ${this.receiptClientForm.get("surname").value}`;
        this.getIdOnce();
      })
    );

    this.subscription.add(
      this.receiptClientForm.get("otherName").valueChanges.subscribe(x => {
        this.receiptClientName = `${this.receiptClientForm.get("firstName").value} ${this.receiptClientForm.get("otherName").value} ${this.receiptClientForm.get("surname").value}`;
      })
    );

    this.subscription.add(
      this.receiptClientForm.get("address").valueChanges.subscribe(x => {
        this.receiptCompanyAddress = x;
      })
    );

    this.subscription.add(
      this.receiptClientForm.get("surname").valueChanges.subscribe(x => {
        this.receiptClientName = `${this.receiptClientForm.get("firstName").value} ${this.receiptClientForm.get("otherName").value} ${this.receiptClientForm.get("surname").value}`;
      })
    );

    this.subscription.add(
      this.receiptClientForm.get("email").valueChanges.subscribe(x => {
        this.receiptClientEmail = x;
      })
    );

    this.subscription.add(
      this.receiptClientForm.get("phoneNumber").valueChanges.subscribe(x => {
        if (x) {
          //Check if phoneNumber is 9 digits
          if ((Number(x) > 100000000) && (Number(x) < 999999999)) {
            // If so isValid
            this.invalidPhoneNumber = false;
          } else {
            // If not isInvalid
            this.invalidPhoneNumber = true;
          };

          this.receiptClientPhoneNumber = x;
        }

      })
    );


    this.vistaForm = this.fb.group({
      itemNumber: [0],
      description: [""],
      quantity: [1],
      rate: [3000],
      itemTotal: [3000],
    });

    this.subscription.add(
      this.vistaForm.get("quantity").valueChanges.subscribe(x => {
        if ((x > 0) || (this.vistaForm.get("rate").value > 0)) {
          this.vistaForm.get("itemTotal").setValue((x) * (this.vistaForm.get("rate").value));
        } else {

        };
      })
    );

    this.subscription.add(
      this.vistaForm.get("rate").valueChanges.subscribe(x => {
        if ((x > 0) || (this.vistaForm.get("quantity").value > 0)) {
          this.vistaForm.get("itemTotal").setValue((x) * (this.vistaForm.get("quantity").value));
        } else {

        };
      })
    );

    this.totalForm = this.fb.group({
      totalCost: [0],
      totalQuantity: [0],
    });

    this.noteForm = this.fb.group({
      number: [''],
      description: [''],
    });

  };

  getIdOnce() {
    console.log("getIdOnce");

    if (!(this.idRequested)) {
      console.log("!(this.idRequested)");
      this.generateId();
      this.idRequested = true;
    } else {

    }
  };

  addReceiptItem(form: any) {

    let total = 0;
    let totalQuantity = 0;

    this.receiptItemArray.push({
      "itemNumber": ((this.receiptItemArray.length) + (1)),
      "description": form.description,
      "quantity": form.quantity,
      "rate": form.rate,
      "itemTotal": form.itemTotal,
    });

    this.receiptItemArray.forEach(element => {
      total += element.itemTotal;
      totalQuantity += element.quantity;
    });

    this.receiptTotalAmount = total;
    this.receiptTotalQuantity = totalQuantity;

    this.totalForm.get("totalQuantity").setValue(totalQuantity);
    this.totalForm.get("totalCost").setValue(total);
  };

  removeReceiptItem(num: any) {

    let total = 0;
    let totalQuantity = 0;

    this.receiptItemArray.splice(num, 1);

    this.receiptItemArray.forEach(element => {
      total += element.itemTotal;
      totalQuantity += element.quantity;
    });

    this.receiptTotalAmount = total;
    this.receiptTotalQuantity = totalQuantity;

    this.totalForm.get("totalQuantity").setValue(totalQuantity);
    this.totalForm.get("totalCost").setValue(total);
  };


  ngAfterViewInit(): void {


  };

  printReceiptPDF() {

    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    this.notify("Downloading Receipt ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.receiptClientName} - Receipt - ${this.dateToday}.pdf`,
      orientationLandscape: false,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    var colorGreen = "#0acf97";
    var colorWhite = "#ffffff";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      currentHeight = 15;

      // Background Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_43_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        0,
        -1,
        210,
        30
      );

      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        docWidth - 60,
        currentHeight - 3,
        35,
        35
      );

      // Logo Image 2
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_logo_350_x_350_center_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        45,
        90,
        120,
        120
      );

      doc.setFontSize(20);
      doc.setTextColor(colorWhite);
      doc.text(`Vista Services, Kenya`, 45, 15, { align: 'center' },);
      doc.setTextColor(colorBlack);

      //line breaker after logo & business info
      // doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 45;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    currentHeight += pdfConfig.lineHeight;

    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight - 7, docWidth - 50, 10, 0, 0);
    doc.setDrawColor(0);

    doc.setFontSize(16);
    doc.text(`Receipt`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(10);
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(125, currentHeight - 2, 60, 35, 0, 0);
    doc.roundedRect(25, currentHeight - 2, 100, 35, 0, 0);
    // doc.roundedRect(25, currentHeight - 2, 160, 35, 0, 0);
    doc.setDrawColor(0);

    currentHeight += pdfConfig.subLineHeight;
    doc.setTextColor(colorBlack);
    doc.text(`Receipt To `, 26, currentHeight, { align: 'left' },);
    doc.text(`Receipt Details `, 129, currentHeight, { align: 'left' },);

    doc.setTextColor(colorGray);
    currentHeight += pdfConfig.subLineHeight;
    doc.text(`Name `, 27, currentHeight, { align: 'left' },);
    doc.text(`:`, 55, currentHeight, { align: 'left' },);
    if (this.receiptClientName) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.receiptClientName}`, 57, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };
    doc.text(`Receipt No. `, 130, currentHeight, { align: 'left' },);
    doc.text(`:`, 160, currentHeight, { align: 'left' },);
    if (this.receiptId) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.receiptId}`, 162, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };

    currentHeight += pdfConfig.subLineHeight;
    doc.text(`Address `, 27, currentHeight, { align: 'left' },);
    doc.text(`:`, 55, currentHeight, { align: 'left' },);
    if (this.receiptCompanyAddress) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.receiptCompanyAddress}`, 57, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };
    doc.text(`Receipt Date `, 130, currentHeight, { align: 'left' },);
    doc.text(`:`, 160, currentHeight, { align: 'left' },);
    if (this.receiptDateCreated) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.receiptDateCreated}`, 162, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };

    currentHeight += pdfConfig.subLineHeight;

    currentHeight += pdfConfig.subLineHeight;
    doc.text(`Email `, 27, currentHeight, { align: 'left' },);
    doc.text(`:`, 55, currentHeight, { align: 'left' },);
    if (this.receiptClientEmail) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.receiptClientEmail}`, 57, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };

    currentHeight += pdfConfig.subLineHeight;
    doc.text(`Phone No. `, 27, currentHeight, { align: 'left' },);
    doc.text(`:`, 55, currentHeight, { align: 'left' },);
    if (this.receiptClientPhoneNumber) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.receiptClientPhoneNumber}`, 57, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };
    doc.text(`Prepared By `, 130, currentHeight, { align: 'left' },);
    doc.text(`:`, 160, currentHeight, { align: 'left' },);
    if (this.loggedInUserDisplayName) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.loggedInUserDisplayName}`, 162, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };

    // If 0 > X < 26
    if ((this.receiptItemArray.length > 0) && (this.receiptItemArray.length < 26)) {
      console.log(`0 > ${this.receiptItemArray.length} < 26`);
      // addPageOne(true);

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 135, 0, 0);

        for (let index = 0; index < 25; index++) {

          if (this.receiptItemArray[index]) {

            doc.text(`${(index + 1)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }


        if (this.receiptTotalAmount) {
          doc.setTextColor(colorBlack);
          doc.text(`Total`, 70, currentHeight, { align: 'right' },);
          // if (this.receiptTotalQuantity) {
          //   doc.text(`${this.receiptTotalQuantity}`, 126, currentHeight, { align: 'center' },);
          // };
          doc.setFontSize(14);
          doc.text(`${formatCurrency((this.receiptTotalAmount), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
          doc.setFontSize(12);
        };

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp
      }

    };
    // If 26 < X < 61
    if ((this.receiptItemArray.length > 26) && (this.receiptItemArray.length < 61)) {
      console.log(`25 > ${this.receiptItemArray.length} < 56`);
      // addPageOne(false);
      // addPageOtherThanOne(25, 56, true);

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 135, 0, 0);

        for (let index = 0; index < 25; index++) {

          if (this.receiptItemArray[index]) {
            doc.text(`${(index + 1)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.subLineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 4, 160, 180, 0, 0);

        for (let index = 26; index < 61; index++) {

          if (this.receiptItemArray[index]) {
            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          };

        };

        if (this.receiptTotalAmount) {
          doc.setTextColor(colorBlack);
          doc.text(`Total`, 70, currentHeight, { align: 'right' },);
          // if (this.receiptTotalQuantity) {
          //   doc.text(`${this.receiptTotalQuantity}`, 126, currentHeight, { align: 'center' },);
          // };
          doc.setFontSize(14);
          doc.text(`${formatCurrency((this.receiptTotalAmount), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
          doc.setFontSize(12);
        };

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp
      }

    };
    // If 61 < X < 96
    if ((this.receiptItemArray.length > 61) && (this.receiptItemArray.length < 96)) {
      console.log(`55 > ${this.receiptItemArray.length} < 86`);
      // addPageOne(false);
      // addPageOtherThanOne(25, 56, false);
      // addPageOtherThanOne(55, 86, true);


      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 135, 0, 0);

        for (let index = 0; index < 25; index++) {
          if (this.receiptItemArray[index]) {
            doc.text(`${(index + 1)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.subLineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 4, 160, 180, 0, 0);

        for (let index = 26; index < 61; index++) {
          if (this.receiptItemArray[index]) {
            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }
      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 180, 0, 0);
        currentHeight += (-4);
        for (let index = 61; index < 96; index++) {

          if (this.receiptItemArray[index]) {
            if (this.receiptItemArray[index]) {
              doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
              if (this.receiptItemArray[index].description) {
                doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
              };
              if (this.receiptItemArray[index].quantity) {
                doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
              };
              if (this.receiptItemArray[index].rate) {
                doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
              };
              if (this.receiptItemArray[index].itemTotal) {
                doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
              };
              currentHeight += pdfConfig.subLineHeight;
            }
          }

        }

        if (true) {
          if (this.receiptTotalAmount) {
            doc.setTextColor(colorBlack);
            doc.text(`Total`, 70, currentHeight, { align: 'right' },);
            // if (this.receiptTotalQuantity) {
            //   doc.text(`${this.receiptTotalQuantity}`, 126, currentHeight, { align: 'center' },);
            // };
            doc.setFontSize(14);
            doc.text(`${formatCurrency((this.receiptTotalAmount), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            doc.setFontSize(12);
          };
          currentHeight += pdfConfig.subLineHeight;
        }

        //// Receipt Details - End

        // Stamp
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp
      }

    };
    // If 96 < X < 131
    if ((this.receiptItemArray.length > 96) && (this.receiptItemArray.length < 131)) {
      console.log(`85 > ${this.receiptItemArray.length} < 116`);
      // addPageOne(false);
      // addPageOtherThanOne(25, 56, false);
      // addPageOtherThanOne(55, 86, false);
      // addPageOtherThanOne(85, 116, true);

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 135, 0, 0);

        for (let index = 0; index < 25; index++) {

          if (this.receiptItemArray[index]) {
            doc.text(`${(index + 1)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }


        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }
      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.subLineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 4, 160, 180, 0, 0);

        for (let index = 26; index < 61; index++) {
          if (this.receiptItemArray[index]) {

            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }
      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 180, 0, 0);
        currentHeight += (-4);
        for (let index = 61; index < 96; index++) {

          if (this.receiptItemArray[index]) {

            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }
      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.subLineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 4, 160, 180, 0, 0);
        for (let index = 96; index < 131; index++) {

          if (this.receiptItemArray[index]) {

            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.receiptItemArray[index].description) {
              doc.text(`${this.receiptItemArray[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.receiptItemArray[index].quantity) {
              doc.text(`${this.receiptItemArray[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.receiptItemArray[index].rate) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.receiptItemArray[index].itemTotal) {
              doc.text(`${formatCurrency((this.receiptItemArray[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        if (this.receiptTotalAmount) {
          doc.setTextColor(colorBlack);
          doc.text(`Total`, 70, currentHeight, { align: 'right' },);
          // if (this.receiptTotalQuantity) {
          //   doc.text(`${this.receiptTotalQuantity}`, 126, currentHeight, { align: 'center' },);
          // };
          doc.setFontSize(14);
          doc.text(`${formatCurrency((this.receiptTotalAmount), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
          doc.setFontSize(12);
        };
        currentHeight += pdfConfig.subLineHeight;


        //// Receipt Details - End

        // Stamp
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.receiptDateCreated) {
          doc.text(` ${this.receiptDateCreated}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp
      }
    };


    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorWhite);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            0,
            doc.internal.pageSize.height - 29,
            210,
            30
          );

          doc.text("Park Medical Center (PMC), 3rd Parklands Avenue, 2nd Floor, Nairobi.", 15, docHeight - 12, { align: 'left' });
          doc.text("+254 718 862 006", 160, docHeight - 12, { align: 'left' });
          doc.text("www.vistaservices.co.ke", 15, docHeight - 3, { align: 'left' });
          doc.text("www.facebook.com/VistaServicesKenya", 116, docHeight - 3, { align: 'left' });
          doc.setTextColor(colorBlack);
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 5,
            doc.internal.pageSize.height - 30,
            { align: 'right' }
          );
          doc.setTextColor(colorWhite);

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_globe_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            8,
            doc.internal.pageSize.height - 7,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_location_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            8,
            doc.internal.pageSize.height - 16,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_phone_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            154,
            doc.internal.pageSize.height - 16,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_facebook_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            110,
            doc.internal.pageSize.height - 7,
            5,
            5
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        0,
        doc.internal.pageSize.height - 29,
        210,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorWhite);
      doc.text("Park Medical Center (PMC), 3rd Parklands Avenue, 2nd Floor, Nairobi.", 15, docHeight - 12, { align: 'left' });
      doc.text("+254 718 862 006", 160, docHeight - 12, { align: 'left' });
      doc.text("www.vistaservices.co.ke", 15, docHeight - 3, { align: 'left' });
      doc.text("www.facebook.com/VistaServicesKenya", 116, docHeight - 3, { align: 'left' });
      doc.setTextColor(colorBlack);
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 5,
        doc.internal.pageSize.height - 30,
        { align: 'right' }
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_globe_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        8,
        doc.internal.pageSize.height - 7,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_location_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        8,
        doc.internal.pageSize.height - 16,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_phone_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        154,
        doc.internal.pageSize.height - 16,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_facebook_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        110,
        doc.internal.pageSize.height - 7,
        5,
        5
      );

      doc.setTextColor(colorWhite);
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  };

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  /**
   * convenience getter for easy access to form fields
   */

  get generalDetailsFormValues() {
    return this.generalDetailsForm.controls;
  }

  get receiptClientFormValues() {
    return this.receiptClientForm.controls;
  }

  get vistaFormValues() {
    return this.vistaForm.controls;
  }

  get noteFormValues() {
    return this.noteForm.controls;
  }



  generateId() {

    console.log("generateId: ");

    // Get new Id
    this.subscription.add(
      this.vistaService.getId().pipe(first()).subscribe((result) => {
        if (result) {
          this.generalDetailsForm.get("id").setValue(result);
          this.receiptId = result;
        }
      })
    );
  }

  editForm(id: string) {

    // Show new tool form
    this.showForm = true;

    // Clear form

    //Populate Form
    // if (this.projectObject) {
    //   if (this.projectObject[id]) {
    //     if (this.projectObject[id][0]) {
    //       if (this.projectObject[id][0].id) { this.projectForm.get("id").setValue(this.projectObject[id][0].id); };
    //       if (this.projectObject[id][0].index) { this.projectForm.get("index").setValue(this.projectObject[id][0].index); };
    //       if (this.projectObject[id][0].description) { this.projectForm.get("name").setValue(this.projectObject[id][0].description); };

    //       if (this.projectObject[id][0].currencyName) { this.projectForm.get("currencyName").setValue(this.projectObject[id][0].currencyName); };
    //       if (this.projectObject[id][0].currencyMagnitudeDealer) { this.projectForm.get("currencyMagnitudeDealer").setValue(this.projectObject[id][0].currencyMagnitudeDealer); };
    //       if (this.projectObject[id][0].currencyMagnitudeCompany) { this.projectForm.get("currencyMagnitudeCompany").setValue(this.projectObject[id][0].currencyMagnitudeCompany); };
    //       if (this.projectObject[id][0].unitsOfMeasure) { this.projectForm.get("unitsOfMeasure").setValue(this.projectObject[id][0].unitsOfMeasure); };
    //       if (this.projectObject[id][0].localCurrencyName) { this.projectForm.get("localCurrencyName").setValue(this.projectObject[id][0].localCurrencyName); };
    //       if (this.projectObject[id][0].localCurrencyISOCode) { this.projectForm.get("localCurrencyISOCode").setValue(this.projectObject[id][0].localCurrencyISOCode); };
    //       if (this.projectObject[id][0].corporateTaxRate) { this.projectForm.get("corporateTaxRate").setValue(this.projectObject[id][0].corporateTaxRate); };
    //       if (this.projectObject[id][0].taxCarryOverPeriod) { this.projectForm.get("taxCarryOverPeriod").setValue(this.projectObject[id][0].taxCarryOverPeriod); };
    //       if (this.projectObject[id][0].discountRate) { this.projectForm.get("discountRate").setValue(this.projectObject[id][0].discountRate); };
    //       if (this.projectObject[id][0].fxRate) { this.projectForm.get("fxRate").setValue(this.projectObject[id][0].fxRate); };
    //       if (this.projectObject[id][0].taxFuelTurnover) { this.projectForm.get("taxFuelTurnover").setValue(this.projectObject[id][0].taxFuelTurnover); };
    //       if (this.projectObject[id][0].product) { this.projectForm.get("product").setValue(this.projectObject[id][0].product); };
    //       if (this.projectObject[id][0].inflation) { this.projectForm.get("inflation").setValue(this.projectObject[id][0].inflation); };
    //       if (this.projectObject[id][0].created) { this.projectForm.get("created").setValue(this.projectObject[id][0].created); };

    //     }
    //   }
    // }
  }

  cancelUpdate() {
    // Show new tool form
    this.showForm = false;

    // Clear form

  };

  removeNoteItem(num: number) {
    this.notesItemArray.splice(num, 1);
  };

  addNoteItem(form: any) {
    this.notesItemArray.push(form.description);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };

}

export const environment = {
  firebase: {
    apiKey: "AIzaSyA0s45vi918-8bimEsBzL_vGUzlUyc4p9s",
    authDomain: "thermal-creek-410815.firebaseapp.com",
    projectId: "thermal-creek-410815",
    storageBucket: "thermal-creek-410815.appspot.com",
    messagingSenderId: "752918533546",
    appId: "1:752918533546:web:a73044297b34c54aca87d3"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};

<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="card">
        <div class="card-header">
            <div class="row flex-grow-1">
                <div class="col">
                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1 class="display-4">Quotations</h1>
                        <p class="lead">List of quotations available.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Logo -->
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-sm-5">
                    <a (click)="createRecord()" class="btn btn-primary mb-2 me-1"><i
                            class="mdi mdi-plus-circle me-2"></i>
                        Create Quotation</a>
                </div>
                <div class="col-sm-7">
                    <div class="text-sm-end">
                    </div>
                </div><!-- end col-->
            </div>
            <!-- end row -->
            <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->

            <!-- user table -->
            <app-advanced-table #advancedTable (handleTableLoad)="handleTableLoad()" [tableData]="quotationArray"
                [columns]="columns" [pagination]="true" [isSearchable]="true" [pageSizeOptions]="pageSizeOptions"
                (search)="searchData($event)" [hasItemNumbering]="true" [isSortable]="true" (sort)="sort($event)"
                tableClasses="table-centered nowrap w-100" theadClasses="table-light">
            </app-advanced-table>
        </div> <!-- end card-body-->
    </div>
</div>
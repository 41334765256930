<div class="card bg-transparent">
    <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->
    <!-- Header -->
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Quotation</h1>
                    <p class="lead text-muted">Fill in the quotation detail.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">1. General Details :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="generalDetailsForm">

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="kesToUSDExchangeRate" class="form-label">Exchange Rate (KES/USD)<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="number" id="kesToUSDExchangeRate" placeholder="Enter the exchange rate (KES/USD)"
                                required formControlName="kesToUSDExchangeRate" min="1" max="1000"
                                [ngClass]="{'is-invalid': (formSubmitted && generalDetailsFormValues['kesToUSDExchangeRate'].invalid)||(generalDetailsFormValues['kesToUSDExchangeRate'].touched && generalDetailsFormValues['kesToUSDExchangeRate'].invalid)}">
                            <div *ngIf="(formSubmitted && generalDetailsFormValues['kesToUSDExchangeRate'].invalid)||(generalDetailsFormValues['kesToUSDExchangeRate'].touched && generalDetailsFormValues['kesToUSDExchangeRate'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="generalDetailsFormValues['kesToUSDExchangeRate'].errors?.['required']">
                                    Exchange Rate (KES/USD) is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="id" class="form-label">Quotation Id.<span class="text-danger"> *</span></label>
                            <input class="form-control" type="text" id="id" required readonly
                                formControlName="id"
                                [ngClass]="{'is-invalid': (formSubmitted && generalDetailsFormValues['id'].invalid)||(generalDetailsFormValues['id'].touched && generalDetailsFormValues['id'].invalid)}">
                            <div *ngIf="(formSubmitted && generalDetailsFormValues['id'].invalid)||(generalDetailsFormValues['id'].touched && generalDetailsFormValues['id'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="generalDetailsFormValues['id'].errors?.['required']">
                                    Quotation Id is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>

        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">2. Client :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="quotationClientForm">

                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label for="firstName" class="form-label">First Name<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="text" id="firstName" placeholder="Enter a first name"
                                required formControlName="firstName" minLength="3" maxLength="15"
                                [ngClass]="{'is-invalid': (formSubmitted && quotationClientFormValues['firstName'].invalid)||(quotationClientFormValues['firstName'].touched && quotationClientFormValues['firstName'].invalid)}">
                            <div *ngIf="(formSubmitted && quotationClientFormValues['firstName'].invalid)||(quotationClientFormValues['firstName'].touched && quotationClientFormValues['firstName'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="quotationClientFormValues['firstName'].errors?.['required']">
                                    First Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="surname" class="form-label">Surname<span class="text-danger"> *</span></label>
                            <input class="form-control" type="text" id="surname" placeholder="Enter a surname" required
                                formControlName="surname" minLength="3" maxLength="15"
                                [ngClass]="{'is-invalid': (formSubmitted && quotationClientFormValues['surname'].invalid)||(quotationClientFormValues['surname'].touched && quotationClientFormValues['surname'].invalid)}">
                            <div *ngIf="(formSubmitted && quotationClientFormValues['surname'].invalid)||(quotationClientFormValues['surname'].touched && quotationClientFormValues['surname'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="quotationClientFormValues['surname'].errors?.['required']">
                                    Surname is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="otherName" class="form-label">Other Name</label>
                            <input class="form-control" type="text" id="otherName" placeholder="Enter another name"
                                required formControlName="otherName" minLength="3" maxLength="15"
                                [ngClass]="{'is-invalid': (formSubmitted && quotationClientFormValues['otherName'].invalid)||(quotationClientFormValues['otherName'].touched && quotationClientFormValues['otherName'].invalid)}">
                            <div *ngIf="(formSubmitted && quotationClientFormValues['otherName'].invalid)||(quotationClientFormValues['otherName'].touched && quotationClientFormValues['otherName'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="quotationClientFormValues['otherName'].errors?.['required']">
                                    Other Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Phone Number</label>
                            <div class="input-group flex-nowrap">
                                <span class="input-group-text" id="basic-addon1">+254</span>
                                <input type="text" class="form-control" placeholder="722 000 000"
                                    formControlName="phoneNumber" aria-label="Username" aria-describedby="basic-addon1"
                                    [ngClass]="{'is-invalid': invalidPhoneNumber}">
                                <div *ngIf="invalidPhoneNumber" class="invalid-tooltip">
                                    <span>
                                        Write a valid Phone Number.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="email" class="form-label">Email address <span
                                    class="text-danger">*</span></label>
                            <input class="form-control" type="email" id="email" placeholder="Enter an email" minLength="3" maxLength="30"
                                formControlName="email">
                        </div>
                    </div>
                </form>

            </div>

            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Quotation To: -->
                        <span>
                            <h5 class="my-0">Quotation Client:</h5><br />
                        </span>
                        <!-- Names -->
                        <span
                            *ngIf="(quotationClientForm.value['firstName']) || (quotationClientForm.value['otherName']) || (quotationClientForm.value['surname'])">
                            Name:
                        </span>
                        <span *ngIf="quotationClientForm.value['firstName']">
                            {{quotationClientForm.value['firstName']}}
                        </span>
                        <span *ngIf="quotationClientForm.value['otherName']">
                            {{quotationClientForm.value['otherName']}}
                        </span>
                        <span *ngIf="quotationClientForm.value['surname']">
                            {{quotationClientForm.value['surname']}}
                        </span>
                        <span
                            *ngIf="(quotationClientForm.value['firstName']) || (quotationClientForm.value['otherName']) || (quotationClientForm.value['surname'])">
                            <br />
                        </span>
                        <!-- Email -->
                        <span *ngIf="quotationClientForm.value['email']">
                            Email: {{quotationClientForm.value['email']}}<br />
                        </span>
                        <!-- Phone Number -->
                        <span *ngIf="quotationClientForm.value['phoneNumber']">
                            Phone Number: +254 {{quotationClientForm.value['phoneNumber']}}<br />
                        </span>

                    </div>
                </div>
            </div>

        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">3. Travel Details :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="travelForm">

                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label for="airlineName" class="form-label">Airline Name</label>

                            <select class="form-select" name="airlineName" formControlName="airlineName"
                                id="airlineName" required>
                                <option *ngFor="let m of airlineListArray" [value]="m">
                                    {{m}}
                                </option>
                            </select>

                            <div *ngIf="(formSubmitted && travelFormValues['airlineName'].invalid)||(travelFormValues['airlineName'].touched && travelFormValues['airlineName'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="travelFormValues['airlineName'].errors?.['required']">
                                    Airline Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="costPerTicketUSD" class="form-label">Cost Per Ticket (USD)</label>
                            <input class="form-control" type="text" id="costPerTicketUSD" placeholder="$600" required
                                formControlName="costPerTicketUSD" min="1" max="10000"
                                [ngClass]="{'is-invalid': (formSubmitted && travelFormValues['costPerTicketUSD'].invalid)||(travelFormValues['costPerTicketUSD'].touched && travelFormValues['costPerTicketUSD'].invalid)}">
                            <div *ngIf="(formSubmitted && travelFormValues['costPerTicketUSD'].invalid)||(travelFormValues['costPerTicketUSD'].touched && travelFormValues['costPerTicketUSD'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="travelFormValues['costPerTicketUSD'].errors?.['required']">
                                    Cost Per Ticket (USD) is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="ticketCount" class="form-label">No. of Tickets</label>

                            <select class="form-select" name="ticketCount" formControlName="ticketCount"
                                id="ticketCount" required>
                                <option *ngFor="let m of personCountArray" [value]="m">
                                    {{m}}
                                </option>
                            </select>

                            <div *ngIf="(formSubmitted && travelFormValues['ticketCount'].invalid)||(travelFormValues['ticketCount'].touched && travelFormValues['ticketCount'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="travelFormValues['ticketCount'].errors?.['required']">
                                    No. of Tickets is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Travel : -->
                        <span>
                            <h5 class="my-0">Travel :</h5><br />
                        </span>
                        <div class="table-responsive-sm" *ngIf="travelForm.value">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1 w-50">
                                            Details
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (USD)
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (KES)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="travelForm.value['airlineName']">
                                                {{(travelForm.value['airlineName'])}}
                                            </span>
                                            <span *ngIf="travelForm.value['ticketCount']">
                                                for {{travelForm.value['ticketCount']}} persons.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="travelForm.value['costUSD']">
                                                {{travelForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="travelForm.value['costKES']">
                                                {{travelForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">4. Treatment Details :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="hospitalForm">

                    <div class="row">
                        <div class="col-md-8 mb-3">
                            <label for="treatmentDescription" class="form-label">Treatment Description</label>
                            <input class="form-control" type="text" id="treatmentDescription"
                                placeholder="Health checkup." required formControlName="treatmentDescription" minLength="3" maxLength="30"
                                [ngClass]="{'is-invalid': (formSubmitted && hospitalFormValues['treatmentDescription'].invalid)||(hospitalFormValues['treatmentDescription'].touched && hospitalFormValues['treatmentDescription'].invalid)}">
                            <div *ngIf="(formSubmitted && hospitalFormValues['treatmentDescription'].invalid)||(hospitalFormValues['treatmentDescription'].touched && hospitalFormValues['treatmentDescription'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="hospitalFormValues['treatmentDescription'].errors?.['required']">
                                    Treatment description is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="costUSD" class="form-label">Cost (USD)</label>
                            <input class="form-control" type="number" id="costUSD" placeholder="$ 5,000" required
                                formControlName="costUSD" min="1" max="1000000"
                                [ngClass]="{'is-invalid': (formSubmitted && hospitalFormValues['costUSD'].invalid)||(hospitalFormValues['costUSD'].touched && hospitalFormValues['costUSD'].invalid)}">
                            <div *ngIf="(formSubmitted && hospitalFormValues['costUSD'].invalid)||(hospitalFormValues['costUSD'].touched && hospitalFormValues['costUSD'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="hospitalFormValues['costUSD'].errors?.['required']">
                                    Treatment Cost (USD) is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Treatment To: -->
                        <span>
                            <h5 class="my-0">Treatment :</h5><br />
                        </span>
                        <!-- Names -->
                        <div class="table-responsive-sm" *ngIf="hospitalForm.value">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1 w-50">
                                            Details
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (USD)
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (KES)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="hospitalForm.value['treatmentDescription']">
                                                {{(hospitalForm.value['treatmentDescription'])}}.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="hospitalForm.value['costUSD']">
                                                {{hospitalForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="hospitalForm.value['costKES']">
                                                {{hospitalForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">5. Accommodation Details :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="accommodationForm">

                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label for="accommodationDescription" class="form-label">Accommodation Description</label>
                            <input class="form-control" type="text" id="accommodationDescription"
                                placeholder="Hotel stay in a budget hotel." required
                                formControlName="accommodationDescription" minLength="3" maxLength="30"
                                [ngClass]="{'is-invalid': (formSubmitted && accommodationFormValues['accommodationDescription'].invalid)||(accommodationFormValues['accommodationDescription'].touched && accommodationFormValues['accommodationDescription'].invalid)}">
                            <div *ngIf="(formSubmitted && accommodationFormValues['accommodationDescription'].invalid)||(accommodationFormValues['accommodationDescription'].touched && accommodationFormValues['accommodationDescription'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="accommodationFormValues['accommodationDescription'].errors?.['required']">
                                    Accommodation description is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="costPerPersonUSD" class="form-label">Cost Per Person (USD)</label>
                            <input class="form-control" type="text" id="costPerPersonUSD" placeholder="$83" required
                                formControlName="costPerPersonUSD" min="1" max="1000"
                                [ngClass]="{'is-invalid': (formSubmitted && accommodationFormValues['costPerPersonUSD'].invalid)||(accommodationFormValues['costPerPersonUSD'].touched && accommodationFormValues['costPerPersonUSD'].invalid)}">
                            <div *ngIf="(formSubmitted && accommodationFormValues['costPerPersonUSD'].invalid)||(accommodationFormValues['costPerPersonUSD'].touched && accommodationFormValues['costPerPersonUSD'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="accommodationFormValues['costPerPersonUSD'].errors?.['required']">
                                    Accommodation cost (USD) is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="personCount" class="form-label">No. of Persons</label>

                            <select class="form-select" name="personCount" formControlName="personCount"
                                id="personCount" required>
                                <option *ngFor="let m of personCountArray" [value]="m">
                                    {{m}}
                                </option>
                            </select>

                            <div *ngIf="(formSubmitted && accommodationFormValues['personCount'].invalid)||(accommodationFormValues['personCount'].touched && accommodationFormValues['personCount'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="accommodationFormValues['personCount'].errors?.['required']">
                                    Number of Persons is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="dayCount" class="form-label">Nights</label>

                            <select class="form-select" name="dayCount" formControlName="dayCount" id="dayCount"
                                required>
                                <option *ngFor="let m of dayCountArray" [value]="m">
                                    {{m}}
                                </option>
                            </select>

                            <div *ngIf="(formSubmitted && accommodationFormValues['dayCount'].invalid)||(accommodationFormValues['dayCount'].touched && accommodationFormValues['dayCount'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="accommodationFormValues['dayCount'].errors?.['required']">
                                    Number of Persons is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Accommodation To: -->
                        <span>
                            <h5 class="my-0">Accommodation :</h5><br />
                        </span>
                        <!-- Names -->
                        <div class="table-responsive-sm" *ngIf="accommodationForm.value">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1 w-50">
                                            Details
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (USD)
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (KES)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="accommodationForm.value['accommodationDescription']">
                                                {{(accommodationForm.value['accommodationDescription'])}}
                                            </span>
                                            <span *ngIf="accommodationForm.value['personCount']">
                                                for {{accommodationForm.value['personCount']}} persons.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="accommodationForm.value['costUSD']">
                                                {{accommodationForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="accommodationForm.value['costKES']">
                                                {{accommodationForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">6. Visa Fees Details :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="visaForm">

                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label for="visaDescription" class="form-label">Visa Description</label>
                            <input class="form-control" type="text" id="visaDescription" minLength="3" maxLength="30"
                                placeholder="Indian Visa Application" required formControlName="visaDescription"
                                [ngClass]="{'is-invalid': (formSubmitted && visaFormValues['visaDescription'].invalid)||(visaFormValues['visaDescription'].touched && visaFormValues['visaDescription'].invalid)}">
                            <div *ngIf="(formSubmitted && visaFormValues['visaDescription'].invalid)||(visaFormValues['visaDescription'].touched && visaFormValues['visaDescription'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="visaFormValues['visaDescription'].errors?.['required']">
                                    Visa description is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="visaApplicantsCount" class="form-label">Visa Applicants</label>

                            <select class="form-select" name="visaApplicantsCount" formControlName="visaApplicantsCount"
                                id="visaApplicantsCount" required>
                                <option *ngFor="let m of personCountArray" [value]="m">
                                    {{m}}
                                </option>
                            </select>

                            <div *ngIf="(formSubmitted && visaFormValues['visaApplicantsCount'].invalid)||(visaFormValues['visaApplicantsCount'].touched && visaFormValues['visaApplicantsCount'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="visaFormValues['visaApplicantsCount'].errors?.['required']">
                                    Visa Applicants count is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="costPerVisaUSD" class="form-label">Cost Per Visa (USD)</label>
                            <input class="form-control" type="text" id="costPerVisaUSD" min="1" max="1000"
                                placeholder="Cost per visa application." required formControlName="costPerVisaUSD"
                                [ngClass]="{'is-invalid': (formSubmitted && visaFormValues['costPerVisaUSD'].invalid)||(visaFormValues['costPerVisaUSD'].touched && visaFormValues['costPerVisaUSD'].invalid)}">
                            <div *ngIf="(formSubmitted && visaFormValues['costPerVisaUSD'].invalid)||(visaFormValues['costPerVisaUSD'].touched && visaFormValues['costPerVisaUSD'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="visaFormValues['costPerVisaUSD'].errors?.['required']">
                                    Cost Per Visa (USD) is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Visa To: -->
                        <span>
                            <h5 class="my-0">Visa :</h5><br />
                        </span>
                        <div class="table-responsive-sm" *ngIf="visaForm.value">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1 w-50">
                                            Details
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (USD)
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (KES)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="visaForm.value['visaDescription']">
                                                {{(visaForm.value['visaDescription'])}}
                                            </span>
                                            <span *ngIf="visaForm.value['visaApplicantsCount']">
                                                for {{visaForm.value['visaApplicantsCount']}} persons.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="visaForm.value['costUSD']">
                                                {{visaForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="visaForm.value['costKES']">
                                                {{visaForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">7. Vista Services Fee Details :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="vistaForm">

                    <div class="row">
                        <div class="col-md-8 mb-3">
                            <label for="vistaDescription" class="form-label">Vista Services Description</label>
                            <input class="form-control" type="text" id="vistaDescription" minLength="3" maxLength="30"
                                placeholder="Vista Services Fee." required formControlName="vistaDescription"
                                [ngClass]="{'is-invalid': (formSubmitted && vistaFormValues['vistaDescription'].invalid)||(vistaFormValues['vistaDescription'].touched && vistaFormValues['vistaDescription'].invalid)}">
                            <div *ngIf="(formSubmitted && vistaFormValues['vistaDescription'].invalid)||(vistaFormValues['vistaDescription'].touched && vistaFormValues['vistaDescription'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="vistaFormValues['vistaDescription'].errors?.['required']">
                                    Vista description is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="vistaApplicantsCount" class="form-label">Applicants</label>

                            <select class="form-select" name="vistaApplicantsCount"
                                formControlName="vistaApplicantsCount" id="vistaApplicantsCount" required>
                                <option *ngFor="let m of personCountArray" [value]="m">
                                    {{m}}
                                </option>
                            </select>

                            <div *ngIf="(formSubmitted && vistaFormValues['vistaApplicantsCount'].invalid)||(vistaFormValues['vistaApplicantsCount'].touched && vistaFormValues['vistaApplicantsCount'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="vistaFormValues['vistaApplicantsCount'].errors?.['required']">
                                    Applicants count is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="costPerPersonUSD" class="form-label">Cost Per Person (USD)</label>
                            <input class="form-control" type="text" id="costPerPersonUSD" min="1" max="1000"
                                placeholder="Cost per vista application." required formControlName="costPerPersonUSD"
                                [ngClass]="{'is-invalid': (formSubmitted && vistaFormValues['costPerPersonUSD'].invalid)||(vistaFormValues['costPerPersonUSD'].touched && vistaFormValues['costPerPersonUSD'].invalid)}">
                            <div *ngIf="(formSubmitted && vistaFormValues['costPerPersonUSD'].invalid)||(vistaFormValues['costPerPersonUSD'].touched && vistaFormValues['costPerPersonUSD'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="vistaFormValues['costPerPersonUSD'].errors?.['required']">
                                    Cost Per Person (USD) is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Vista Fee : -->
                        <span>
                            <h5 class="my-0">Vista Fee :</h5><br />
                        </span>
                        <div class="table-responsive-sm" *ngIf="vistaForm.value">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1 w-50">
                                            Details
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (USD)
                                        </th>
                                        <th class="p-0 px-1 w-25">
                                            Cost (KES)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="vistaForm.value['vistaDescription']">
                                                {{(vistaForm.value['vistaDescription'])}}
                                            </span>
                                            <span *ngIf="vistaForm.value['vistaApplicantsCount']">
                                                for {{vistaForm.value['vistaApplicantsCount']}} persons.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="vistaForm.value['costUSD']">
                                                {{vistaForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="vistaForm.value['costKES']">
                                                {{vistaForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">8. Notes :</h4>
            </div>
            <div class="card-body">
                <!-- form -->



                <div class="row">

                    <form name="signup-form" [formGroup]="noteForm" *ngIf="noteForm">
                        <div>
                            <div class="input-group input-group-sm">
                                <span class="input-group-text" id="inputGroup-sizing-sm">{{notesItemArray.length +
                                    1}}</span>
                                <input class="form-control" type="text" id="description"
                                    placeholder="The airline ticket prices may change depending on time & availability."
                                    required formControlName="description" minLength="3" maxLength="70"
                                    [ngClass]="{'is-invalid': (formSubmitted && noteFormValues['description'].invalid)||(noteFormValues['description'].touched && noteFormValues['description'].invalid)}">
                                <button class="btn btn-outline-success" *ngIf="!submitInProgress && !updateInProgress"
                                    (click)="addNoteItem(noteForm.value)" type="button">Add Item</button>
                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </form>


                    <!-- <form name="signup-form" [formGroup]="noteForm"
                            *ngIf="showProjectDealerOperatingExpenseEdit">
                            <div>
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text"
                                        id="inputGroup-sizing-sm">{{notesItemArray.length + 1}}</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" placeholder="Cost" id="total"
                                        aria-label="Sizing example input" formControlName="total"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-success"
                                        *ngIf="!submitInProgress && !updateInProgress"
                                        (click)="addDealerOperatingExpenseItem(noteForm.value,'salariesAndBonus')"
                                        type="button">Add Item</button>
                                    <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                        *ngIf="submitInProgress || updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </form> -->
                </div>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Notes To: -->
                        <span>
                            <h5 class="my-0">Notes :</h5><br />
                        </span>
                        <div class="row text-center" *ngIf="!(notesItemArray.length > 0)">
                            <div class="row flex-grow-1">
                                <div class="col">
                                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                        <h1 class="display-4">Empty</h1>
                                        <p class="lead">No Notes!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive-sm" *ngIf="(notesItemArray.length > 0)">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1">
                                            No.
                                        </th>
                                        <th class="p-0 px-1">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of notesItemArray; let i = index;">
                                        <td class="p-0 px-1">{{i+1}}.</td>
                                        <td class="p-0 px-1">{{m}}</td>
                                        <td class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                    *ngIf="!submitInProgress && !updateInProgress"
                                                    (click)="removeNoteItem(i)"
                                                    type="button">X</button>
                                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                    *ngIf="submitInProgress || updateInProgress">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">8. Preview :</h4>
            </div>
            <div class="card-body">
                <div class="card border border-primary"
                    *ngIf="(quotationClientForm.value['firstName']) || (quotationClientForm.value['otherName']) || (quotationClientForm.value['surname'])">
                    <div class="card-body">
                        <!-- Quotation To: -->
                        <span>
                            <h5 class="my-0">Quotation Client:</h5><br />
                        </span>
                        <span
                            *ngIf="(quotationClientForm.value['firstName']) || (quotationClientForm.value['otherName']) || (quotationClientForm.value['surname'])">
                            Name:
                        </span>
                        <span *ngIf="quotationClientForm.value['firstName']">
                            {{quotationClientForm.value['firstName']}}
                        </span>
                        <span *ngIf="quotationClientForm.value['otherName']">
                            {{quotationClientForm.value['otherName']}}
                        </span>
                        <span *ngIf="quotationClientForm.value['surname']">
                            {{quotationClientForm.value['surname']}}
                        </span>
                        <span
                            *ngIf="(quotationClientForm.value['firstName']) || (quotationClientForm.value['otherName']) || (quotationClientForm.value['surname'])">
                            <br />
                        </span>
                        <!-- Email -->
                        <span *ngIf="quotationClientForm.value['email']">
                            Email: {{quotationClientForm.value['email']}}<br />
                        </span>
                        <!-- Phone Number -->
                        <span *ngIf="quotationClientForm.value['phoneNumber']">
                            Phone Number: +254 {{quotationClientForm.value['phoneNumber']}}<br />
                        </span>
                        <!-- Travel : -->
                    </div>
                </div>
                <div class="card border border-primary">
                    <div class="card-body">
                        <div class="table-responsive-sm">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr class="text-muted">
                                        <th class="p-0 px-1 w-50 text-center">
                                            Details
                                        </th>
                                        <th class="p-0 px-1 text-end w-25">
                                            Cost (USD)
                                        </th>
                                        <th class="p-0 px-1 text-end w-25">
                                            Cost (KES)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="travelForm.value">
                                    <tr>
                                        <td class="p-0">
                                            <br />
                                            <h5 class="my-0">Travel :</h5>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="travelForm.value['airlineName']">
                                                1. {{(travelForm.value['airlineName'])}}
                                            </span>
                                            <span *ngIf="travelForm.value['ticketCount']">
                                                for {{travelForm.value['ticketCount']}} persons.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="travelForm.value['costUSD']">
                                                {{travelForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="travelForm.value['costKES']">
                                                {{travelForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="hospitalForm.value">
                                    <tr>
                                        <td class="p-0">
                                            <br />
                                            <h5 class="my-0">Treatment :</h5>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="hospitalForm.value['treatmentDescription']">
                                                2. {{(hospitalForm.value['treatmentDescription'])}}.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="hospitalForm.value['costUSD']">
                                                {{hospitalForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="hospitalForm.value['costKES']">
                                                {{hospitalForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="accommodationForm.value">
                                    <tr>
                                        <td class="p-0">
                                            <br />
                                            <h5 class="my-0">Accommodation :</h5>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="accommodationForm.value['accommodationDescription']">
                                                3. {{(accommodationForm.value['accommodationDescription'])}}
                                            </span>
                                            <span *ngIf="accommodationForm.value['personCount']">
                                                for {{accommodationForm.value['personCount']}} persons.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="accommodationForm.value['costUSD']">
                                                {{accommodationForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="accommodationForm.value['costKES']">
                                                {{accommodationForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="visaForm.value">
                                    <tr>
                                        <td class="p-0">
                                            <br />
                                            <h5 class="my-0">Visa :</h5>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="visaForm.value['visaDescription']">
                                                4. {{(visaForm.value['visaDescription'])}}
                                            </span>
                                            <span *ngIf="visaForm.value['visaApplicantsCount']">
                                                for {{visaForm.value['visaApplicantsCount']}} persons.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="visaForm.value['costUSD']">
                                                {{visaForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="visaForm.value['costKES']">
                                                {{visaForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="vistaForm.value">
                                    <tr>
                                        <td class="p-0">
                                            <br />
                                            <h5 class="my-0">Vista Services :</h5>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span *ngIf="vistaForm.value['vistaDescription']">
                                                5. {{(vistaForm.value['vistaDescription'])}}
                                            </span>
                                            <span *ngIf="vistaForm.value['vistaApplicantsCount']">
                                                for {{vistaForm.value['vistaApplicantsCount']}} persons.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="vistaForm.value['costUSD']">
                                                {{vistaForm.value['costUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="vistaForm.value['costKES']">
                                                {{vistaForm.value['costKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="totalForm.value">
                                    <tr>
                                        <td class="p-0"><br /></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1 text-center">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="totalForm.value['totalCostUSD']">
                                                {{totalForm.value['totalCostUSD'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="totalForm.value['totalCostKES']">
                                                {{totalForm.value['totalCostKES'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card border border-primary" *ngIf="(notesItemArray.length>0)">
                    <div class="card-body">
                        <!-- Notes -->
                        <span>
                            <h5 class="my-0">Notes :</h5><br />
                        </span>

                        <div class="row text-center" *ngIf="!(notesItemArray.length > 0)">
                            <div class="row flex-grow-1">
                                <div class="col">
                                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                        <h1 class="display-4">Empty</h1>
                                        <p class="lead">No Notes!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive-sm" *ngIf="(notesItemArray.length>0)">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1">
                                            No.
                                        </th>
                                        <th class="p-0 px-1">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of notesItemArray; let i = index;">
                                        <td class="p-0 px-1">{{i+1}}.</td>
                                        <td class="p-0 px-1">{{m}}</td>
                                        <td class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeNoteItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>







            </div>
        </div>

    </div>

    <div class="card-footer">
        <div class="card mb-0 bg-success text-white" role="button" (click)="navigateTo('quotationPDF')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-download-outline"></i> Save & Print Quotation (PDF)</h4>
                </div>
            </div>
        </div>
    </div>


</div>
import { Route } from "@angular/router";
import { MenuItem } from "../layouts/shared/models/menu.model";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UserGuideComponent } from "./user-guide/user-guide.component";
import { AuthGuard } from "../core/guards/auth.guard";
import { SupportComponent } from "./support/support.component";
import { BookingComponent } from "./booking/booking.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { QuotationComponent } from "./quotation/quotation.component";
import { ReceiptComponent } from "./receipt/receipt.component";
import { QuotationListComponent } from "./quotation-list/quotation-list.component";
import { BookingListComponent } from "./booking-list/booking-list.component";
import { InvoiceListComponent } from "./invoice-list/invoice-list.component";
import { ReceiptListComponent } from "./receipt-list/receipt-list.component";

export const  PORTAL_MENU: MenuItem[] = [
    { key: 'ds-help', label: 'Portal Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/portal/dashboard' },
    { key: 'ds-help', label: 'Quick Links', isTitle: true },
    { key: 'app-quotation', label: 'Quotation', isTitle: false, icon: 'uil-tachometer-fast', collapsed: false,
        children: [
            { key: 'app-quotation-list', label: 'Create Quotation', link: '/portal/quotation/create', parentKey: 'app-quotation' },
            { key: 'app-quotation-create', label: 'Quotation List', link: '/portal/quotation', parentKey: 'app-quotation' },
        ]
    },
    { key: 'apps-invoice', label: 'Invoice', isTitle: false, icon: 'uil-tachometer-fast', collapsed: false,
        children: [
            { key: 'apps-invoice-list', label: 'Create 3rd Park Invoice', link: '/portal/invoice/create', parentKey: 'apps-invoice' },
            { key: 'apps-invoice-create', label: 'Invoice List', link: '/portal/invoice', parentKey: 'apps-invoice' },
        ]
    },
    { key: 'app-receipt', label: 'Receipt', isTitle: false, icon: 'uil-tachometer-fast', collapsed: false,
        children: [
            { key: 'app-receipt-list', label: 'Create Receipt', link: '/portal/receipt/create', parentKey: 'app-receipt' },
            { key: 'app-receipt-create', label: 'Receipt List', link: '/portal/receipt', parentKey: 'app-receipt' },
        ]
    },
    { key: 'app-booking', label: 'Bookings', isTitle: false, icon: 'uil-tachometer-fast', collapsed: false,
        children: [
            { key: 'app-booking-list', label: 'Create Bookings', link: '/portal/booking/create', parentKey: 'app-booking' },
            { key: 'app-booking-create', label: 'Bookings List', link: '/portal/booking', parentKey: 'app-booking' },
        ]
    },
  ]

export const  PORTAL_ROUTES: Route[] = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full', },
    {
        path: 'dashboard',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: DashboardComponent, },
        ]
    },
    {
        path: 'quotation',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: QuotationListComponent, },
            { path: ':id', component: QuotationComponent, },
            { path: 'create', component: QuotationComponent, },
        ]
    },
    {
        path: 'invoice',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: InvoiceListComponent, },
            { path: ':id', component: InvoiceComponent, },
            { path: 'create', component: InvoiceComponent, },
        ]
    },
    {
        path: 'receipt',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: ReceiptListComponent, },
            { path: ':id', component: ReceiptComponent, },
            { path: 'create', component: ReceiptComponent, },
        ]
    },
    {
        path: 'booking',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: BookingListComponent, },
            { path: ':id', component: BookingComponent, },
            { path: 'create', component: BookingComponent, },
        ]
    }
];